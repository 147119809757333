function ButtonBuy({ text }) {
  return(
    <>
      <a
        href="https://pay.hotmart.com/X45076203O?off=1ayqwdqi&checkoutMode=10"
        className="btn-cta"
      >
        {text}
      </a>
    </>
  );
}

export default ButtonBuy;

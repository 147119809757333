function Warranty() {
  return(
    <div>
      <h3 className="headline"> Garantia de 30 dias </h3>
      <p className="paragraph-text">
        Você tem, por lei, o direito de testar qualquer produto durante 7 dias.
        Mas, comigo será diferente. Eu vou te dar 30 dias de garantia para você
        entrar na plataforma e experimentar o curso. Se dentro desse prazo suas
        expectativas não forem atendidas você poderá solicitar o reembolso de 100% do seu dinheiro.
      </p>
    </div>
  );
}

export default Warranty;

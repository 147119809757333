import { Carousel } from '3d-react-carousal';
import eloa from '../images/@bc.eloa.PNG';
import diego from '../images/@diego.oliveiracosta.PNG';
import marlos from '../images/@marlosvitorio.PNG';
import paul from '../images/@paulbarroncas.PNG';
import pedro from '../images/@pedrohenrique_souza76.PNG';
import milton from '../images/@milton.neto_.PNG';

const slides = [
  <img src={eloa} alt="eloa" />,
  <img src={diego} alt="diego" />,
  <img src={marlos} alt="marlos" />,
  <img src={paul} alt="paul" />,
  <img src={pedro} alt="pedro" />,
  <img src={milton} alt="milton" />
]

const SocialProof = () => {
  return (
    <div >
      <h3 className="headline"> Veja quem já conseguiu evoluir </h3>

        <Carousel slides={slides} />
        {/* <div className="video-container">
          <h4 className="subtitle-video"> Marquinhos Nascimento </h4>
          <div className="player-wrapper-double">
             <Youtube className="react-player" videoId="f27hCY4o_20" opts={videoOpts} />
          </div>
        </div> */}

        {/* <div className="video-container">
          <h4 className="subtitle-video"> Alberto Trabulsi </h4>
          <div className="player-wrapper-double">

            <Youtube className="react-player" videoId="DPI6FVGP2io" opts={videoOpts} />
          </div>
        </div> */}



    </div>
  );
};

export default SocialProof;


import React from 'react';

const PainPoint = () => {
  return (
    <React.Fragment>
      <div className="card-container">
        <div className="card">
          <h4 className="headline card-title"> Não se adapta a cavalos diferente? </h4>
          <p className="paragraph-text">
            Será abordado no curso princípios e técnicas de comunicação com seu cavalo que te possibilita se adaptar e tirar o melhor desempenho dele, você passará a ter mais controle sobre os cavalos e fazer uma carreira bem feita.
          </p>
        </div>
        <div className="card">
          <h4 className="headline card-title"> Tem uma evolução lenta por ter pouco tempo para passar os cavalos ? </h4>
          <p className="paragraph-text">
            Esse curso vai fazer você otimizar seus treinos nos seus horários disponíveis e complementá-los com uma metodologia de treinos alternativos.
          </p>
        </div>
      </div>

    </React.Fragment>
  )
}

export default PainPoint;

function About() {
  return (
    <div>
      <h3 className="headline"> Quem é Aécio Levy ? </h3>
      <p className="paragraph-text">
        Aécio Levy é apaixonado por vaquejada.
        Sua especialidade é no desenvolvimento das habilidades
        de vaqueiros para terem melhores desempenhos na vaquejada,
        pois acredita que todos vaqueiros podem evoluir, tendo a mentalidade
        certa e a preparação correta de treino.
        Seu maior objetivo é ajudar vaqueiros a correrem de
        forma consistente, se apresentarem bem e ganharem prêmios.
      </p>
    </div>
  );
}
export default About;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#f0e3ca'
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff8303',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      paper: '#3c3932'
    },
    type: 'dark',
  },
});

function getSteps() {
  return ['Afiando nossa Mentalidade', 'Preparação Mental', 'Nivelamento', '1ª Fase da corrida de boi: CVC', '2ª Fase da corrida de boi: SSP', 'Fundamentos da esteira'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `É muito importante que tenha uma mentalidade correta para que possamos percorrer a jornada de melhorar qualquer habilidade.
              Por isso, primeiro vamos analisar porque estamos fazendo isso, os medos ao redor dessa prática da vaquejada, qual o preço
              que devo pagar para chegar onde eu quero. E ainda mais um método para sermos mais produtivos e assertivos nos treinos.`;
    case 1:
      return `Hoje em dia, a maioria dos atletas em diversos esportes fazem preparação mental como parte da sua
              rotina de treinamento. E o vaqueiro não deixa de ser um atleta. A preparação mental permite que o
              atleta possa desempenhar o máximo das suas habilidades na pista de competição, sem que ele seja
              atrapalhado com problemas externos, nervosismo e ansiedade.
              Nesse módulo você irá desde fazer exercícios até construir sua própria rotina
              de preparação mental antes de correr o boi.`;
    case 2:
      return `É um módulo voltado para os iniciantes que precisam ter uma base de montaria antes de iniciar nos bois.`;
    case 3:
      return `Se pararmos para pensar uma carreira de boi tem vários passos que são executados de forma muito rápida.
              Seria muito confuso e difícil propor-se a ficar melhor em todas as etapas de uma vez só.
              Para isso dividimos a carreira de boi em duas fases, e dentro dessas fases existem as etapas que iremos focar.
              Por exemplo, iremos fazer treinos de cancela, volta e corrida, até que você
              consiga repetir boa parte desses fundamentos corretamente em boa parte das carreiras, para então focar em outras partes.
      `;
    case 4:
      return `A segunda fase da corrida é a hora de soltar o cavalo, sair de sela e puxar o boi.
              Como a vaquejada é um esporte sequencial, a segunda fase pode ser afetada pela primeira.
              Por isso focamos primeiramente em executar a primeira fase bem.
              Essa segunda fase consiste em treinamentos específicos para automatização de movimentos
              envolvidos para que você chegue a fazer tudo isso de forma inconsciente e automática.`;
    case 5:
      return `Esteira sem dúvidas tem uma importância enorme na carreira de um boi e como falado anteriormente,
              pode dificultar ou facilitar algumas etapas da carreira. Iremos aqui desconstruir boa parte do
              que o melhor esteireiro do Brasil Nego Edson faz, não só para que você possa replicar,
              mas até para que você possa analisar quem você vai convidar para está ao seu lado na próxima vez que for correr.`;
    default:
      return 'Unknown step';
  }
}

function Methodology() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <h3 className="headline"> Metodologia </h3>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Voltar
                  </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Concluído' : 'Próximo'}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button}>
              Resetar
          </Button>
          </Paper>
        )}
      </div>
    </ThemeProvider>
  );
}

export default Methodology;

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PainPoint = () => {
  return (
    <React.Fragment>
      <div className="image-and-text">
        <div className="image-container-pp2">
          <LazyLoadImage className="img-style" alt="vaqueiro" src="https://aecio-static-pages-assets.s3.amazonaws.com/images/saida-de-sela.JPG" />
          <a href="https://guilhermesoares.art.br/" className="link-caption"> 📸 Guilherme Fotografias </a>
        </div>
        <div>
          <h3 className="headline"> Não consegue fazer tudo que sabe na corrida por conta do nervosismo ou ansiedade </h3>

          <p className="paragraph-text">
            Preparar sua mente para estar em um estado mental que te possibilite correr com praticamente toda suas habilidade em qualquer corrida, mesmo que você corra com pouca frequência, para que você consiga colocar o que você treina em prática nas competições.
          </p>

        </div>
      </div>
    </React.Fragment>
  )
}

export default PainPoint;

import React from 'react';

const PainPoint = () => {
  return (
    <React.Fragment>
      <div className="card-container">
        <div className="card">
          <h4 className="headline card-title"> Falta de condição ou oportunidade </h4>
          <p className="paragraph-text">
            Por mais que você não tenha condições de correr, animal, pista ou bois para praticar, você terá a oportunidade de aperfeiçoar suas habilidades com treinos alternativos fora da pista que servem para te fazer evoluir dentro das pistas de competição
          </p>
        </div>
        <div className="card">
          <h4 className="headline card-title"> Falta de constância nas suas carreiras </h4>
          <p className="paragraph-text">
            Você não consegue ter uma sequência de corridas com desempenho parecido.
            Apesar de correr em um cavalo que seja bom, às vezes o desempenho é excelente,
            enquanto em outras corridas pode cair bastante. Se você tem consciência do que
            faz a tendência que não tem uma variação enorme de uma vaquejada para outra
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PainPoint;

const titulos = [
  "🏆 Melhor vaqueiro pelo Ranking ABQM no ano de 2017",
  "🏆 Segundo melhor vaqueiro pelo Ranking ABQM no ano de 2018",
  "🏆 Melhor vaqueiro pelo Ranking ABQM no ano de 2019",
  "🏆 Melhor estereiro profissional pelo CPV no ano de 2015",
  "🏆 Melhor estereiro profissional pelo CPV no ano de 2017",
  "🏆 Melhor estereiro profissional pelo CPV no ano de 2018",
  "🏆 Melhor estereiro profissional pelo CPV no ano de 2019",
  "🏆 Melhor estereiro profissional pelo CPV no ano de 2020",
]

function VaqueiroSurpresa() {
  return (
    <div>
      <h3 className="headline"> Participação especial de Nego Edson nas aulas do curso </h3>
      <p className="paragraph-text">
        Nego Edson é um vaqueiro profissional com mais de 20 anos de experiência,
        que já ganhou inúmeras corridas em sua vida e vários outros prêmios individuais, como:
      </p>
      <ul className="list-parent">
        {titulos.map(titulo => <li className="list-items"> {titulo} </li>)}
      </ul>
      <p className="paragraph-text underline">
        <strong>
          E ele irá participar das aulas práticas do curso e compartilhar o que ele fez para chegar onde chegou⁣.
        </strong>
      </p>
    </div>
  );
}

export default VaqueiroSurpresa;

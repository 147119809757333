const bonusLS = [
  "Gravações das reuniões das mentorias feitas até o momento (hoje eu cobrari o valor de uma mentoria no valor de R$ 2.000,00)",
  "Uma super aula do curso Connect Vaquejada - CVAQ para treinamento de cavalos de vaquejadas",
  "Algumas lives selecionadas a dedo do programa Papo de Vaqueiro",
  "A oportunidade de enviar suas carreiras para serem analisadas quem sabe até ser descontruída nas aulas do curso"
];

const Bonus = () => {
  return (
    <div>
      <h3 className="headline"> Bonus Exclusivos </h3>
      <ul className="list-parent">
        {bonusLS.map(bonuses => <li className="list-items"> {bonuses} </li>)}
      </ul>
    </div>
  );
};

export default Bonus;

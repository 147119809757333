import { LazyLoadImage } from 'react-lazy-load-image-component';

const PainPoint1 = () => {
  return(
    <div className="image-and-text">
      <div>
        <h3 className="headline"> Não sabe por onde começar ou o que treinar </h3>
        <p className="paragraph-text">
          O Desconstruindo a Carreira de Boi é um curso completo, que vai desde a estratégia para o treinamento de vaqueiro até a <strong> preparação mental </strong> para vaquejada.

        </p>
        <p className="paragraph-text">
          Passando pelos fundamentos da <strong> cancela, carreira, arriada da sela, puxada, como trabalhar o boi</strong>, imprevistos que podem acontecer durante a carreira e algumas estratégias para correr todo tipo de boi.
          Vaquejada realmente é um esporte muito caro, mas você pode sim chegar a correr de graça.
        </p>
      </div>
      <div className="image-container">
        <LazyLoadImage
          className="img-style" alt="vaqueiro" src="https://aecio-static-pages-assets.s3.amazonaws.com/images/rabo.jpg"
        />
        <a href="https://guilhermesoares.art.br/" className="link-caption"> 📸 Guilherme Fotografias </a>
      </div>
    </div>
  );
}

export default PainPoint1;

// import visa from '../icons/visa.svg';
// import master from '../icons/mastercard.svg';
// import offerPNG from '../icons/Oferta.png';

const Offer = () => {
  return (
    <div>
      <h4 className="headline"> Tudo isso por </h4>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center'}}>
          {/* 12 de 77,75 */}
          <h4 className="no-margin"> de 997,00 à vista ou </h4>
          <h2 className="no-margin"> <strong> 12x de R$ </strong> </h2>
        </div>
        <div>
          <h2 className="no-margin font-price"> 97 <sup className="cents-price">, 14</sup> </h2>
        </div>
      </div>
    </div>
  );
}

export default Offer;

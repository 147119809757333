function Promessa() {
  return (
    <h5 className="subtitle">
      O Desconstruindo a Carreira de Boi é um treinamento completo e aprofundado para os vaqueiros
      e não vaqueiros que querem aprender como correr boi da melhor forma possível e assim se preparar
      para correr de forma consistente, se apresentar bem e ganhar prêmios nas vaquejadas que participar.
    </h5>
  );
}

export default Promessa;

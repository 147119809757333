import './App.css';
import Youtube from 'react-youtube';
import Promessa from './components/Promessa';
import VaqueiroSurpresa from './components/VaqueiroSurpresa';
import PainPoint1 from './components/PainPoint1';
import PainPoint2 from './components/PaintPoint2';
import PainPoint3 from './components/PaintPoint3';
import PainPoint4 from './components/PaintPoint4';
import SocialProof from './components/SocialProof';
// import Transformation from './components/Transformation';
import Bonus from './components/Bonus';
import SuperBonus from './components/SuperBonus';
import Offer from './components/Offer';
import Disclaimer from './components/Disclaimer';
import Warranty from './components/Warranty';
import About from './components/About';
import Doubt from './components/Doubt';
import Contact from './components/Contact';
import ButtonBuy from './components/ButtonBuy';
import Methodology from "./components/Methodology";

const videoOpts = {
  height: '100%',
  width: '100%',
};

function Space() {
  return (
    <div style={{ marginTop: '2rem'}}>

    </div>
  );
}

function App() {
  const showPage = (timeLimit) => {
    let timeLimitDate = timeLimit;
    let now = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
    let nowDate = new Date(now);
    console.log(nowDate);
    console.log(timeLimitDate)
    return nowDate >= timeLimitDate;
  }
  const FINAL = new Date('May 24, 2021 23:59:00 UTC-03:00');

  const show = !showPage(FINAL);

  return (
    <>
    { show ?
      <div className="App-header">
      <h1 className="headline"> Seja um vaqueiro tirador de <br /> prêmios 🏆💰 </h1>
      <Promessa />
      {/* Youtube video section */}
      <div className="video-container">
        <div className="player-wrapper">
          <Youtube className="react-player" videoId="Ttd95CcoKLs" opts={videoOpts}/>
        </div>
      </div>
      {/* CTA 1 */}
      <ButtonBuy text={<>"Sim! Quero uma <br /> metodologia de treinamento"</>} />
      {/* Participacao especial */}
      <VaqueiroSurpresa />
      {/* Dor 1 */}
      <PainPoint1 />
      {/* Dor 2 */}
      <PainPoint2 />
      <Space />
      {/* Dor 3 */}
      <PainPoint3 />
      {/* Space */}
      <Space />
      {/* Dor 4 */}
      <PainPoint4 />
      {/* space */}
      <Space />
      {/* metodologia */}
      <Methodology />
      <ButtonBuy text="Quero minha vaga" />
      {/* Bonus */}
      <Bonus />
      {/* SuperBonus */}
      <SuperBonus />
      {/* Social Proof */}
      <SocialProof />
      {/* Offer */}
      <Offer />
      {/* Warranty */}
      <Warranty />
      <Contact />
      {/* About */}
      <About />
      {/* Doubt */}
      <Doubt />
      <ButtonBuy text="Quero testar por 30 dias" />
      {/* Transformation */}
      {/* <Transformation /> */}
      {/* Disclaimer */}
      <Disclaimer />
    </div>
    :

    <h2 className="headline"> Turma encerrada </h2>

    }
    </>
  );
}

export default App;

import { SvgIcon } from '@material-ui/core';
import { ReactComponent as WhatsApp} from '../icons/whatsapp-brands.svg';

function Contact() {
  return (
    <>
      <h4 className="headline"> Ainda tem dúvidas ou está com algum problema </h4>
      <a
        className="btn-cta"
        style={{ backgroundColor: '#25D366', color: 'white' }}
        href="https://api.whatsapp.com/send?phone=5588993488746&text=Opa!%20Gostaria%20de%20tirar%2[…]sobre%20o%20curso%20Desconstruindo%20a%20Carreira%20de%20Boi"
      >
        <SvgIcon component={WhatsApp} viewBox="0 0 600 476.6" />
        Clique aqui e fale com um membro da equipe pelo whatsapp
      </a>
    </>
  );
}

export default Contact

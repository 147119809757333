function SuperBonus() {
  return (
    <div>
      <h3 className="headline"> Super Bônus </h3>
      <ul className="list-parent">
        <li className="list-items">
          1 cobertura do Premium Eternaly WJS ou Leo Leo ICL para cada um dos 20 primeiros a se inscreverem no curso.
        </li>
      </ul>
      <p className="paragraph-text">
        * Você vai escolher de qual será
      </p>
      <p className="paragraph-text">
        * Tem o período de 1 ano pra pegar a cobertura
        (caso algum esses cavalos não esteja disponível no momento do pedido, você terá direito a outro do haras)
      </p>
    </div>
  );
}

export default SuperBonus;

import Accordion from '@material-ui/core/Accordion';
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ReactComponent as ExpandIcon } from '../icons/chevron-down-solid.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1rem',
    color: '#ff8303',
    textAlign: 'left',
  },
  content: {
    color: '#f0e3ca',
    fontSize: '1rem'
  }
}));

const theme = createMuiTheme({
  palette: {
    text: {
      primary: '#f0e3ca'
    },
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff8303',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      paper: '#3c3932'
    },
    type: 'dark',
  },
  typography: {
    fontSize: '1.1rem',
    htmlFontSize: '12px'
  },
});

function Doubt() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme} >
      <div>
        <h3 className="headline"> Duvidas Frequentes ? </h3>
        <Accordion>
          <AccordionSummary
            className={classes.root}
            expandIcon={<ExpandIcon style={{ width: '1rem', color: '#f0e3ca'}} />}
          >
            Vai ter aulas práticas?
          </AccordionSummary>
          <AccordionDetails
            className={classes.content}
          >
            Sim, teremos várias aulas práticas que ainda contarão com a participação de Nego Edson.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandIcon style={{ width: '1rem', color: '#f0e3ca' }} />}
            className={classes.root}
          >
            Como terei acesso ao curso?
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            Será enviado um e-mail para você com o link de acesso ao curso após a confirmação do seu pagamento,
            e você poderá assistir as aulas tanto pelo celular quanto pelo computador através da plataforma da hotmart.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandIcon style={{ width: '1rem', color: '#f0e3ca' }} />}
            className={classes.root}> Por quanto tempo tenho acesso ao curso? </AccordionSummary>
          <AccordionDetails className={classes.content}>
            Você terá acesso ao curso pelo período de 2 anos.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandIcon style={{ width: '1rem', color: '#f0e3ca' }} />}
            className={classes.root}> Quais as formas de pagamento </AccordionSummary>
          <AccordionDetails className={classes.content}>
            Você pode pagar por boleto, cartão de crédito, transferência ou PIX.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandIcon style={{ width: '1rem', color: '#f0e3ca' }} />}
            className={classes.root}> Como funciona a garantia de 30 dias? </AccordionSummary>
          <AccordionDetails className={classes.content}>
            Após a compra você terá 30 dias de garantia para pedir seu dinheiro de volta,
            caso não esteja satisfeito com o curso,
            basta entrar em contato com o suporte da hotmart e pedir seu dinheiro de volta.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandIcon style={{ width: '1rem', color: '#f0e3ca' }} />}
            className={classes.root}> Como saber se sou um dos 20 primeiros compradores e ganhei a cobertura? </AccordionSummary>
          <AccordionDetails className={classes.content}>
            Após 7 dias divulgaremos os ganhadores da cobertura,
            pois precisamos desse tempo para contabilizar
            os boletos que foram pagos e os que não forem.
          </AccordionDetails>

        </Accordion>
      </div>
    </ThemeProvider>
  );
}

export default Doubt;

const Disclaimer = () => {
  return (
    <div>
      {/* <h3> Satisfação garantida </h3>
      <p className="paragraph-text">
        Para acabar com qualquer dúvida, que você possa
        ter a respeito da utilidade e eficácia deste curso,
        te ofereço uma garantia exclusica: se, em até 30 dias
        após a sua compra, você não estiver satisfeito com o produto
        e as informações fornecidas, te devolverei 100% do valor que
        você pagou.
      </p> */}
      {/* <p className="paragraph-text"> Sem perguntas ;D </p> */}
      <p className="paragraph-text" style={{ textDecoration: 'underline' }}> Aviso Legal </p>
      <p className="paragraph-text">
        Este produto não garante a obtenção de resultados.
        Qualquer referência ao desempenho de uma estratégia não
        deve ser interpretada como uma garantia de resultados
      </p>
    </div>
  );
}

export default Disclaimer;
